import { graphql } from 'gatsby';
export default from './index';

export const indexQuery = graphql `
  query indexENQuery {
    contentfulIndexContent(node_locale: {eq: "en"}) {
      ...IndexQueryFragment
    }
  }
`
